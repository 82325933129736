// export const BASE_URL = "https://vml-api-test.azurewebsites.net";
export const BASE_URL = "https://vml-api.azurewebsites.net";
//export const BASE_URL = "https://localhost:44382";

// export const BASE_URL = "https://localhost:7251";

export const poStatus = [
  { id: 1, name: "Active" },
  { id: 2, name: "Canceled" },
];
export const jobStatus = [
  { id: 1, name: "Closed", enabledRoles: [200, 500], enableEvents: [8, 12] },
  { id: 2, name: "Canceled", enabledRoles: [200, 500] },
  { id: 3, name: "Active" },
  {
    id: 4,
    name: "Checked",
    enabledRoles: [100],
  },
];

export const shipmentModes = [
  {
    id: 100,
    mode: "Air Freight",
    icon: "airMode.svg",
  },
  {
    id: 200,
    mode: "Sea Freight",
    icon: "seaMode.svg",
  },
  {
    id: 400,
    mode: "International Courier Services",
    icon: "courierMode.svg",
  },
  {
    id:500,
    mode:'Road – Cross Border Trucking',
    icon:'road.svg'
  },
  {
    id:600,
    mode:'Road – Transportation/ OBD',
    icon:'road.svg'
  },
  {
    id:700,
    mode:'Others',
    icon:'road.svg'
  }
];

export const CargoPickupTypes = [
  { id: 100, name: "Local" },
  { id: 200, name: "Pickup" },
  { id: 300, name: "Incoming" },
];

export const CargoTypes = [
  { id: 100, name: "General" },
  { id: 200, name: "Hazardes" },
];

export const ShipmentPriorities = [
  { id: 100, name: "Normal" },
  { id: 200, name: "Express" },
];

export const TransportModes = [
  { id: 100, name: "Air" },
  { id: 200, name: "Sea" },
  { id: 300, name: "Road" },
  { id: 400, name: "Courier" },
  { id: 500, name: "Others" },
];

export const movementStatuses = [
  { id: 100, name: "NA" },
  { id: 200, name: "InWarehouse" },
  { id: 300, name: "InTransit" },
  { id: 400, name: "Delivered" },
  { id: 500, name: "Shipped" },
];

export const JobTitles = [
  { id: 100, name: "HR" },
  { id: 200, name: "Accountant" },
];
export const Roles = [
  { id: 100, name: "CSR" },
  { id: 200, name: "Admin" },
  { id: 300, name: "Agent" },
  { id: 400, name: "Customer" },
  { id: 500, name: "SuperAdmin" },
];
// export const Roles = [
//   { id: 100, name: "CSR" },
//   { id: 200, name: "Admin" },
//   { id: 300, name: "Agent" },
//   { id: 400, name: "Customer" },
// ];
export const documentTypes = [
  { id: 1, name: "Commercial Invoice" },
  { id: 2, name: "Packing List" },
  { id: 3, name: "POD" },
  { id: 4, name: "Airway Bill" },
  { id: 5, name: "Bill Of Lading" },
  { id: 6, name: "Warehouse Note" },
  { id: 7, name: "VML Billing Invoice" },
  { id: 8, name: "Photos" },
  { id: 9, name: "Pre-Alert" },
  { id: 10, name: "Others" },
];
export const uom = [
  { id: 1, name: "Pallets" },
  { id: 2, name: "Boxes" },
];

export const jobTypes = [
  {
    id: 400,
    name: "O-WH",
  },
  {
    id: 100,
    name: "WH-WH",
  },
  {
    id: 200,
    name: "WH-V",
  },
  {
    id: 300,
    name: "WH-D",
  },
];

export const getUniqueId = () => {
  const uniqueId = `${Date.now().toString()}-${Date.now().toString(
    36
  )}-${Math.random().toString(36).substring(2, 9)}`;
  return uniqueId;
};

export const formatToUTCDateExtended = (inputDate) => {
  let formattedDate = null;
  if (inputDate !== null) {
    const datePartInInputDate = new Date(inputDate).getDate();
    const monthPartInInputDate = new Date(inputDate).getMonth();
    const yearPartInInputDate = new Date(inputDate).getFullYear();

    let datePartInInputDateAsString = "";
    let monthPartInInputDateAsString = "";

    if (datePartInInputDate < 10) {
      datePartInInputDateAsString = "0" + datePartInInputDate.toString();
    } else {
      datePartInInputDateAsString = datePartInInputDate.toString();
    }

    if (monthPartInInputDate < 10) {
      monthPartInInputDateAsString = "0" + monthPartInInputDate.toString();
    } else {
      monthPartInInputDateAsString = monthPartInInputDate.toString();
    }

    formattedDate = new Date(
      Date.UTC(
        yearPartInInputDate,
        parseInt(monthPartInInputDateAsString),
        parseInt(datePartInInputDateAsString)
      )
    );
  } else {
    formattedDate = null;
  }

  return formattedDate;
};
