import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, documentTypes } from "../../constants";
import LoadingPanel from "../job/JobLoader";
import AppContext from "../../context/app-context";
import Editor from 'react-simple-wysiwyg';
import moment from "moment-timezone";


export const DocumentForm = ({
  documents,
  job,
  documentUploaded,
  documentDeleted,
  formDisabled,
  setState,
}) => {
  const authCtx = useContext(AppContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [mailData, setMailData] = useState({
    to: '',
    cc: '',
    subject: '',
    body: '',
    attachments: [],
  });
  const mobLabels = {
    100: "Air Freight",
    200: "Sea Freight",
    300: "Road",
    400: "Courier",
    500: "Other"
  };
  

  const addPreAlertEvent = async () => {
    const preAlertEvent = {
      logisticsEventTypeId: 14, 
      eventName: "Pre-Alert Sent",
      eventDescription: "Pre-Alert has been successfully sent.",
      eventDate: moment().tz("Asia/Singapore").toISOString(),// SG Timezone
    };

    setLoader(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/Job/addEvent?jobId=${job.id}`,
        preAlertEvent
      );

      if (res.status === 200) {
        // Format Event Details: User Name / DateTime in desired format
        const eventDetails = `${authCtx.profile.displayname.split(" ")[0]} / ${moment()
          .tz("Asia/Singapore")
          .format("DD.MM.YY – HHmm")}hrs`;

        preAlertEvent.id = res.data; // New event ID from the server
        setState((prevState) => ({
          ...prevState,
          job: {
            ...prevState.job,
            events: [
              {
                eventName: preAlertEvent.eventName, // "Pre-Alert Sent"
                eventDetails, // "Linda / 13.01.25 – 1614hrs"
                eventDate: preAlertEvent.eventDate, // For internal use
              },
              ...(prevState.job?.events || []),
            ],
          },
        }));

        toast.success("Pre-Alert event added successfully!");
        window.location.reload();
      } else {
        toast.error("Failed to add Pre-Alert event.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while adding Pre-Alert event.");
    } finally {
      setLoader(false);
    }
  };

  const formattedTime = moment().tz("Asia/Singapore").format("YYYY-MM-DD HH:mm");
  console.log("Time : " + formattedTime);
  
  const handleMailClick = async (file) => {
    try {
      setLoader(true);
      // Get the document using axios
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/Storage/documents/download?id=${file.documentID}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authCtx.profile.token}`,
        },
      });

      // Convert blob to File object
      const blob = new Blob([response.data], { type: response.data.type });
      const attachment = new File([blob], file.fileName, {
        type: response.data.type,
      });

      setSelectedDocument(file);
      console.log("adsasd");
      setMailData({
        to: file.email || '',
        cc: file.cc,
        subject: `${job?.vesselName && job?.vesselName !== '' ? job.vesselName : ''}/${job?.jobNo && job?.jobNo !== '' ? job.jobNo : ''}–${mobLabels[job?.mot] && mobLabels[job?.mot] !== '' ? mobLabels[job?.mot] : ''}-Pre-Alert for${job?.awb && job?.awb !== '' ? `-${job?.awb}` : ''} –${job?.originPortCode && job?.originPortCode !== '' ? `-${job?.originPortCode}` : ''}-${job?.destinationPortCode && job?.destinationPortCode !== '' ? job?.destinationPortCode : ''}`,
        body: `<p>Good Day,</p>
        <p><strong>Vessel Name :</strong> ${job?.vesselName}</p>
        <p><strong>AWB/ BL No. :</strong> ${job?.awb}</p>
        <p><strong>Flight Dtls :</strong> </p>
        <p><strong>ETD :</strong> ${job?.etd?.toLocaleDateString("en-IN")}</p>
        <p><strong>ETA :</strong> ${job?.eta?.toLocaleDateString("en-IN")}</p>
        
        ${tableRows.length > 0 ? `<div class="mb-4">
          <table style="width: 100%; height: auto; text-align: left; font-size: 12px; border: 1px solid #ddd; border-collapse: collapse; margin-top:10px;">
            <thead style="background-color: #f2f2f2; text-transform: uppercase; font-size: 10px;">
              <tr>
                <th style="padding: 10px; border: 1px solid #ddd;">PO Number</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Vessel</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Supplier</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Ch.weight(KG)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Volume(CBM)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Weight(KG)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">LxWxH</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
        </div>`: ""}
        
        <p><strong>Shipped to :</strong></p>
        <p>${job?.consigneeName}</p>
        <p>${job?.consigneeAddress}</p>
        <p><strong>TEL :</strong></p>
        <p><strong>Email :</strong></p>
        <br/>
        <p>Thank you & Best Regards.</p>
        <p>VML (${authCtx.profile.displayname})</p>`,
        attachments: [{
          file: attachment,
          name: file.fileName,
          size: file.size,
          type: response.data.type
        }],
      });
      console.log("ads");

      setModalOpen(true);
    } catch (error) {
      console.error("Error preparing email attachment:", error);
      toast.error("Failed to prepare email attachment");
    } finally {
      setLoader(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDocument(null);
    setMailData({
      to: '',
      subject: '',
      cc: '',
      body: '',
      attachments: [],
    });
  };
  console.log(job);

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('ToEmail', mailData.to);
      formData.append('CC', mailData.cc);
      formData.append('Subject', mailData.subject);
      formData.set('Body', mailData.body);


      // Append the pre-alert document if it exists
      if (mailData.attachments.length > 0) {
        formData.append('attachments', mailData.attachments[0].file);
      }

      // Send email using axios
      const response = await axios.post(
        `${BASE_URL}/Job/send`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authCtx.profile.token}`,
          },
        }
      );
      addPreAlertEvent()

      if (response.status === 200) {
        toast.success('Email sent successfully!');
        handleCloseModal();
      }
    } catch (error) {
      console.error('Error sending email:', error);
      const errorMessage = error.response?.data?.message || 'Failed to send email';
      toast.error(errorMessage);
    } finally {
      setLoader(false);
    }
  };
  const tableRows = job?.poes
    ? job.poes.map((v, pid) => {
      if (v.package) {
        return `
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.poNo}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.vesselName}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.supplierName}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.package?.chargableWeight?.toFixed(2)}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.package?.volume?.toFixed(2)}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.package?.weight?.toFixed(2)}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${v.package?.length}X${v.package?.width}X${v.package?.height}</td>
          </tr>
        `;
      }
    }).join('')
    : '';

  useEffect(() => {


    setMailData({
      to: '',
      subject: `${Array.isArray(job?.vesselName) && job?.vesselName.length > 1 ? job?.vesselName[0] : job?.vesselName}/${job?.jobNo}–Airfreight-Pre-Alert for-${job?.awb || job?.blNumber || ''} –${job?.originPortCode}-${job?.destinationPortCode}`,
      cc: "",
      body: `
        <p>Good Day,</p>
        
        <p><strong>Vessel Name :</strong> ${job?.vesselName}</p>
        <p><strong>AWB/ BL No. :</strong> ${job?.awb}</p>
        <p><strong>Flight Dtls :</strong> </p>
        <p><strong>ETD :</strong> ${job?.etd?.toLocaleDateString("en-IN")}</p>
        <p><strong>ETA :</strong> ${job?.eta?.toLocaleDateString("en-IN")}</p>
        
        ${tableRows.length > 0 ? `<div class="mb-4">
          <table style="width: 100%; height: auto; text-align: left; font-size: 12px; border: 1px solid #ddd; border-collapse: collapse; margin-top:10px;">
            <thead style="background-color: #f2f2f2; text-transform: uppercase; font-size: 10px;">
              <tr>
                <th style="padding: 10px; border: 1px solid #ddd;">PO Number</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Vessel</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Supplier</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Ch.weight(KG)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Volume(CBM)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Weight(KG)</th>
                <th style="padding: 10px; border: 1px solid #ddd;">LxWxH</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
        </div>`: ""}
        <p><strong>Shipped to :</strong></p>
        <p>${job?.consigneeName}</p>
        <p>${job?.consigneeAddress}</p>
        <p><strong>TEL :</strong></p>
        <p><strong>Email :</strong></p>
        <br/>
        <p>Thank you & Best Regards.</p>
        <p>VML (${authCtx.profile.displayname}) </p>
      `,
      attachments: []
    });
  }, [job]);



  useEffect(() => {
    if (documents) {
      setSelectedFiles(
        documents.map((d) => ({
          ...d,
          documentTypeObject: documentTypes.find(
            (x) => x.id === d.documentTypeId
          ) || null,
        }))
      );
    }
  }, [documents, documentTypes]);

  const onDocTypeChange = (e, docId) => {
    const newDocType = e.value || e.target.value;

    setSelectedFiles(
      selectedFiles.map((f) =>
        f.documentID === docId
          ? {
            ...f,
            documentTypeId: newDocType?.id || newDocType,
            documentTypeObject: newDocType,
          }
          : f
      )
    );
  };
  const onCheckBoxChange = (e, docId) => {
    setSelectedFiles(
      selectedFiles.map((f) =>
        f.documentID === docId ? { ...f, showInPortal: e.target.checked } : f
      )
    );
  };

  const downloadFile = async (file) => {
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await fetch(
        `${BASE_URL}/Storage/documents/download?id=${file.documentID}`,
        {
          config,
          method: "GET",
          headers: {
            Authorization: `Bearer ${authCtx.profile.token}`, // Replace with your actual access token
            // You may need to set other headers depending on your API requirements
          },
        }
      );
      if (response.ok) {
        const blob = await response.blob();

        // // Create a download link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName; // Set the desired filename
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching blob content:", error);
    }
  };

  const onSaveDocType = async () => {
    const newdocs = selectedFiles.map((f) => ({
      id: f.documentID,
      documentTypeId: f.documentTypeId,
      showInPortal: f.showInPortal,
      otherDocType: f.otherDocType,
    }));
    const res = await axios.post(
      `${BASE_URL}/storage/documents/updatedoctype`,
      newdocs
    );
    if (res.status === 200) {
      toast.success("Document updated successfully!");
      setState((pre) => ({ ...pre, documents: selectedFiles }));
    } else toast.error("An error occured while updating the document");
  };

  const changeHandler = async (event) => {
    setLoader(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    const res = await axios.post(
      `${BASE_URL}/storage/documents/upload`,
      formData,
      config
    );
    if (res.status === 200) {
      // save cargo
      setLoader(false);
      toast.success("Document uploaded successfully!");
      documentUploaded(res.data);
      const procFiles = res.data.map((f) => {
        return { documentID: f.id, fileName: f.fileName, size: f.size };
      });
      setSelectedFiles([...selectedFiles, ...procFiles]);
    } else {
      setLoader(false);
      toast.error("some error occured when document uploading!");
    }
  };

  const deleteDoc = async (docId) => {
    const res = await axios.post(
      `${BASE_URL}/storage/documents/delete?id=${docId}`
    );
    if (res.status === 200) {
      toast.success("Document deleted successfully!");
      const exDocIndex = selectedFiles.findIndex((x) => x.documentID === docId);
      selectedFiles.splice(exDocIndex, 1);
      documentDeleted(docId);
    } else toast.error("An error occured while deleting the document");
  };

  return (
    <div className="xl:w-full p-5 md:w-11/12 sm:w-3/6 xs:w-3/6 pt-5 h-[75vh]">
      <p className="font-medium text-lg text-black">Documents</p>
      <form className="space-y-8 divide-y divide-gray-200 h-full">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="mt-3.5 grid grid-cols-1 gap-y-7 gap-x-4 sm:grid-cols-6 sm:gap-y-5">
            <div className="sm:col-span-8 flex flex-col">
              <label
                htmlFor="document"
                className="block text-sm font-normal text-gray-900"
              ></label>
              <div className="mt-1 w-full flex h-full">
                <div className="mx-6 w-1/3">
                  <div className="relative flex flex-col items-center justify-center h-80 border-2 border-gray-400 border-dotted">
                    <input
                      type="file"
                      className="absolute documents cursor-pointer h-full w-full border-0 focus:outline-none opacity-0"
                      name="file"
                      onChange={changeHandler}
                      disabled={formDisabled}
                    />
                    <img
                      className="h-10 w-auto mb-4"
                      src="upload.svg"
                      alt="Upload"
                    />
                    <h6 className="text-sm font-medium text-zinc-600 mb-1">
                      Attach Documents
                    </h6>
                    <p className="text-xs font-normal text-gray-400">
                      Drag Documents here
                    </p>
                  </div>
                </div>
                {loader && (
                  <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image" />
                    <div className="k-loading-color" />
                  </div>
                )}
                <div className="mt-2 ml-1 flex flex-col w-2/3 h-[65vh] overflow-hidden">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:pr-8 h-full">
                      <div className="overflow-auto w-fit h-[50vh] pb-5">
                        <table className="min-w-full">
                          <tbody className="">
                            {selectedFiles &&
                              selectedFiles.map((file, fileIdx) => (
                                <tr
                                  key={file.documentID}
                                  className="border-b border-dashed border-gray-400"
                                >
                                  <td className="w-12 whitespace-nowrap py-3 pl-2 pr-1 text-sm font-medium text-gray-900">
                                    <img
                                      className="h-6 w-auto mb-4"
                                      src="pdf.svg"
                                      alt="pdf"
                                    />
                                  </td>
                                  <td
                                    className="max-w-[15vw] text-ellipsis truncate py-3 pr-1 text-sm font-medium text-gray-900 h-fit"
                                    title={file.fileName}
                                  >
                                    {file.fileName}
                                    <p className="text-sm text-gray-500 font-normal">
                                      {file.size}KB
                                    </p>
                                  </td>
                                  <td
                                    colSpan={file.documentTypeId === 8 ? 3 : 1}
                                    className="whitespace-nowrap w-full xl:min-w-full min-w-max px-3 flex flex-col gap-2 py-3 text-sm text-gray-500 h-full"
                                  >
                                    <div id="compo" className="h-8 w-fit">
                                      <ComboBox
                                        data={
                                          job
                                            ? documentTypes
                                            : documentTypes.filter((doc) => doc.name !== "Pre-Alert")
                                        }
                                        name="documentType"
                                        textField="name"
                                        className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                                        dataItemKey="id"
                                        suggest={true}
                                        clearButton={true}
                                        placeholder="Document Type"
                                        value={file.documentTypeObject}
                                        onChange={(e) =>
                                          onDocTypeChange(e, file.documentID)
                                        }
                                        disabled={formDisabled}
                                      />
                                    </div>
                                    {file.documentTypeId === 10 && (
                                      <div className="h-8">
                                        <input
                                          type="text"
                                          name="otherDocType"
                                          className="h-full w-full block shadow-sm sm:text-sm text-xs placeholder:text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                          placeholder="Specify The Document type"
                                          value={file.otherDocType}
                                          onChange={(e) =>
                                            setSelectedFiles(
                                              selectedFiles.map((f) =>
                                                f.documentID === file.documentID
                                                  ? {
                                                    ...f,
                                                    otherDocType:
                                                      e.target.value,
                                                  }
                                                  : f
                                              )
                                            )
                                          }
                                          disabled={formDisabled}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      class={`${file.documentTypeId === 8
                                        ? "w-full"
                                        : "w-24"
                                        } flex flex-col`}
                                    >
                                      <div>
                                        <input
                                          type="checkbox"
                                          id="showInPortal"
                                          name="showInPortal"
                                          value={file.showInPortal}
                                          checked={file.showInPortal}
                                          onClick={(e) =>
                                            onCheckBoxChange(e, file.documentID)
                                          }
                                          class="peer h-3 w-3"
                                          required
                                          disabled={formDisabled}
                                        />
                                        <label
                                          for="hosting-small"
                                          class="ml-2 text-xxs items-center w-full text-gray-500 bg-white cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600"
                                        >
                                          Show in Portal
                                        </label>
                                      </div>
                                      {/* {file.documentTypeId === 8 && (
                                        <ActionColumn
                                          file={file}
                                          deleteDoc={deleteDoc}
                                        />
                                      )} */}
                                    </div>
                                  </td>

                                  {/* {file.documentTypeId !== 8 && ( */}
                                  <td className="relative flex h-full items-center justify-center mt-1/2 pl-1 pr-2 text-right text-sm font-medium sm:pr-6">
                                    <div className="flex">
                                      {file.documentTypeObject?.name === 'Pre-Alert' && (
                                        <button
                                          type="button"
                                          className="ml-2 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2"
                                          onClick={() => handleMailClick(file)}
                                          disabled={formDisabled}
                                        >
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-6 w-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                          </svg>

                                        </button>)}
                                      <button
                                        className="ml-2 inline-flex items-center border border-transparent text-xs font-medium rounded shadow-sm text-whit focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        //href={`${BASE_URL}/storage/documents/download?id=${file.documentID}`}
                                        //href={`${BASE_URL}/storage/documents/download?id=${file.documentID}`}
                                        type="button"
                                        disabled={formDisabled}
                                        onClick={() => downloadFile(file)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                          />
                                        </svg>
                                      </button>

                                      <button
                                        type="button"
                                        className="ml-2 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() =>
                                          deleteDoc(file.documentID)
                                        }
                                        disabled={formDisabled}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </td>
                                  {/* )} */}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {selectedFiles.length !== 0 && (
                        <div className="sm:w-32 mt-7 ml-auto">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center py-2 px-4 border border-lime-700 bg-lime-700 border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                            onClick={onSaveDocType}
                            disabled={formDisabled}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white p-6 rounded shadow-lg relative w-[60rem] max-h-[90vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className="text-2xl font-semibold mb-2">Pre-Alert</h1>
            {loader ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <form onSubmit={handleSendEmail}>
                <div className="mb-4">
                  <label
                    htmlFor="to"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    To:
                  </label>
                  <input
                    type="email"
                    id="to"
                    required
                    value={mailData.to}
                    onChange={(e) => setMailData({ ...mailData, to: e.target.value })}
                    className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="cc"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    CC:
                  </label>
                  <input
                    type="text"
                    id="cc"
                    value={mailData.cc}
                    onChange={(e) => setMailData({ ...mailData, cc: e.target.value })}
                    className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Subject:
                  </label>
                  <input
                    type="text"
                    id="subject"
                    required
                    value={mailData.subject}
                    onChange={(e) => setMailData({ ...mailData, subject: e.target.value })}
                    className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  {/* <label
                    htmlFor="body"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Content:
                  </label> */}
                  <Editor
                    value={mailData.body}
                    onChange={(e) => setMailData({ ...mailData, body: e.target.value })}
                    containerProps={{
                      className: "border border-gray-300 rounded min-h-[320px]"
                    }}
                  />
                </div>
                <div className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-1">Attachments:</h3>
                  {mailData.attachments.length > 0 ? (
                    <div className="border border-gray-300 p-3 rounded">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                          </svg>
                          <div>
                            <p className="text-sm font-medium">{mailData.attachments[0].name}</p>
                            <p className="text-xs text-gray-500">{(mailData.attachments[0].size / 1024).toFixed(2)} KB</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-gray-500 text-sm">No attachments</p>
                  )}
                </div>
                <button
                  type="submit"
                  disabled={loader}
                  className="mt-4 p-2 w-full bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
                >
                  {loader ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                      <span>Sending...</span>
                    </>
                  ) : (
                    <span>Send Email</span>
                  )}
                </button>
              </form>
            )}
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-black"
              aria-label="Close Modal"
            >
              ✖
            </button>
          </div>
        </div>
      )}

    </div>
  );
};
