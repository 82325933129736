import React, { useContext, useState } from "react";
import axios from "axios";
import AppContext from "../context/app-context";
import { BASE_URL } from "../constants";
import { useNavigate } from "react-router-dom";

export default function LogIn() {
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setInvalidLogin(false);
    setLoading(true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    // send the username and password to the server
    const payload = { username: username, password: password };
    axios
      .post(`${BASE_URL}/Auth/login`, payload, config)
      .then((res) => {
        setInvalidLogin(true);
        authCtx.login(res.data);
      })
      .catch((error) => {
        setInvalidLogin(true);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="min-h-full flex">
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1582517378602-f109b395ce40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-40 w-auto" src="logo.png" alt="Workflow" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Sign in to VML
              </h2>
            </div>

            <div className="mt-8">
              <div>
                <div></div>

                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>

              <div className="mt-6">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username
                    </label>
                    <div className="mt-1">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Enter Username"
                        value={username}
                        onChange={({ target }) => setUsername(target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Enter Password"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {loading && (
                        <svg
                          class="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Sign in
                    </button>

                    {invalidLogin && (
                      <p
                        className="mt-5 text-rg font-semibold text-red-600 animate-bounce"
                        id="email-error"
                      >
                        Invalid username or password !
                      </p>
                    )}
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      className=" text-xs font-medium text-blue-600"
                      type="button"
                      onClick={() => {
                        navigate("/resetPassword");
                      }}
                    >
                      Forgot Password ?
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
