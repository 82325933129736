import React, { useEffect, useState } from "react";
import { shipmentModes } from "../../constants";

function QuotationDocumentContent({ quotation, componentRef,onSavePo }) {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    setTerms(termsAndConditions);
  }, []);

  const termsAndConditions = {
    "Air Freight": [
      "Validity of this Quotation is as indicated above.",
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      "Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Shipments containing hazardous or restricted items must comply with International Air Transport Association (IATA) regulations. Additional charges and documentation requirements may apply.",
      " Charges quoted are subjected to changes according to ACTUAL Cargo details & Operations requirements.",
      " Rates & Services proposals are made based on capacity conditions at time of enquiry & subjected to changes according to market conditions.",
      " Subjected to Carrier’s Rates, Schedules, Space & Acceptance at time of ACTUAL Booking.",
      " Transit times are indicative only & subjected to changes according to airline’s routing & schedules. Neither VML nor the Airline guarantees specific delivery timelines.",
      "Customs Clearance at the destination is consignee’s responsibility unless otherwise specified. Any duties, taxes and related charges are EXCLUDED unless explicitly mentioned in the quotation.",
      " Cargo Marine Insurance coverage is not included in the quoted rates unless specifically requested and agreed upon. Liability for loss, damage, or delay is limited to the terms outlined by the airline or applicable conventions.",
      " Airline may impose cancellation fees, which will be charged back accordingly, if applicable.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      "By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "International Courier Services": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Customs duties, taxes or other destination country charges are not included and is payable by Consignee, unless specified.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Transit times are estimated and subjected to changes according to Carrier’s schedule, routing and or any other unforeseen delays. Neither VML nor the Carrier guarantees exact delivery timelines.",
      " Shipments must comply with International Courier Carrier’s guidelines for prohibited & restricted items. VML & Carrier reserves the right to refuse any shipment not in compliance.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry. In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier.",
      'VML is not liable for delays, losses, or damages caused by force majeure "events", including but not limited to natural disasters, strikes, or political unrest.',
      " Any cancellation or change requests must be communicated prior to shipment dispatched. Additional fees may apply for such requests.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "Sea Freight": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Hazardous or restricted cargo must comply with the International Maritime Dangerous Goods (IMDG) Code. Additional charges and documentation may apply for such shipments.",
      " Bookings are subjected to final confirmation based on carrier’s space availability, schedule & operational requirements at time of shipment.",
      " Transit times are estimated and subjected to changes due to port congestion, weather, customs clearance and or any other unforeseen circumstances. Neither VML nor the Carrier guarantees specific delivery timelines.",
      " Customs duties, taxes or other origin & destination country charges are not included and is payable by Consignee, unless specified.",
      " The client is responsible for providing accurate & complete documentation for the shipment. Any delays or penalties resulting from incorrect or missing documents are the client’s responsibility.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry. In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier/ Shipping Line.",
      " Carrier/ Shipping Line may impose cancellation fees, which will be charged back accordingly, if applicable.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "Road – Cross Border Trucking": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided(qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Transit times are estimated and subjected to changes due to traffic, customs clearance, weather and or any other unforeseen delays.Neither VML nor the Carrier guarantees exact delivery timelines.",
      " Customs duties, taxes or other origin & destination country charges are not included and is payable by Consignee, unless specified.",
      " The client is responsible for providing accurate & complete documentation for the shipment.Any delays or penalties resulting from incorrect or missing documents are the client’s responsibility.",
      " The client must inform VML of any Hazardous or restricted goods included in the shipment.Additional charges may apply for such cargo.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry.In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions.A copy of which, is available upon request.",
    ],
    "Road – Transportation/ OBD": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Any standby, waiting time, port charges etc., will be charged, if applicable.",
      " Any other additional equipment/ services required, will be quoted & charged accordingly.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " VML’s liability ends once cargo is signed clean by recipient",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    Others: [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " This quotation is applicable only to the services described above.",
      " Any other additional equipment/ services required, will be quoted & charged accordingly.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
  };
  return (
    <div
      id="content-print"
      style={{ width: "750px" }}
      className="bg-neutral-100 noBorder px-3 py-3 pr-6 overflow-y-auto overflow-x-hidden space-b-5 rounded h-full w-full border border-neutral-700"
      ref={componentRef}
    >
      <div className="flex flex-col items-center justify-center  bg-white  rounded-t-md  py-2">
        <img
          src="LetterHead.png"
          className="h-full w-full object-contain"
          alt="Logo"
        />
        <h2 className="text-xl  py-2 font-semibold  ">
          Quote No: {quotation?.quotationNo || "N/A"}
        </h2>
      </div>

      <div className="flex flex-col items-center text-center mb-3">
        <div className="bg-white px-8 pt-4 pb-4 rounded-b-md border border-gray-200 w-full ">
          <div className=" gap-4 flex justify-between flex-col">
            <div className="gap-4 flex justify-between">
              <div className=" text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Customer</p>
                  <p className="text-xs text-gray-800 font-medium w-52">
                    {quotation?.customerName || "N/A"}
                  </p>
                  <p className="text-xxs w-32 font-medium whitespace-pre-line mt-1 leading-3 text-gray-600">
                    {quotation?.customerAddress || "N/A"}
                  </p>
                </div>
              </div>

              <div className="  text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Origin Port</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.originPortName || "Not Specified"}
                  </p>
                </div>
              </div>

              <div className="  text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Destination Port</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.destinationPortName || "Not Specified"}
                  </p>
                </div>
              </div>
              <div className="  text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Currency</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.currencyCode || "Not Specified"}
                  </p>
                </div>
              </div>
            </div>

            <div className="gap-4 flex justify-between">
              <div className="  text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1 w-52">
                    Quotation Date
                  </p>
                  <p className="text-sm text-gray-800">
                    {quotation?.quotationDate
                      ? new Date(quotation.quotationDate)
                          .toLocaleDateString("en-GB")
                          .split("/")
                          .join("-")
                      : "N/A"}
                  </p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Validity Date</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.validityDate
                      ? new Date(quotation?.validityDate)
                          .toLocaleDateString("en-GB")
                          .split("/")
                          .join("-")
                      : "_/ _/_ _ _"}
                  </p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Email</p>
                  <p className="text-xs text-gray-800">
                    {quotation?.customerEmail}
                  </p>
                </div>
              </div>

              <div className="col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Contact Number</p>
                  <p className="text-xs text-gray-800">
                    {quotation?.customerPhone}
                  </p>
                </div>
              </div>
            </div>

            {quotation?.comments && (
              <div className="flex gap-2 text-left">
                <p className="mt-2 text-xs font-medium text-gray-800">
                  Comment:{" "}
                </p>
                <p className="mt-2 text-xs font-medium text-gray-800">
                  {quotation?.comments}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex w-full gap-3  mt-2 ">
        <div className="bg-white px-8 py-3 rounded w-full">
          <div className="flex gap-5 justify-between">
            <div>
              <p className="text-xs text-neutral-400 font-medium">
                Pieces/ Packages
              </p>
              <p className="text-xxs font-medium">
                {" "}
                {quotation?.cargoDetails?.quantity} Pcs
              </p>
            </div>
            <div>
              <p className="text-xs text-neutral-400 font-medium">
                Charge Weight
              </p>
              <p className="text-xxs font-medium">
                {" "}
                {quotation?.cargoDetails?.chargeWeight} Kg
              </p>
            </div>
            <div>
              <p className="text-xs text-neutral-400 font-medium">
                Gross Volume
              </p>
              <p className="text-xxs font-medium">
                {quotation?.cargoDetails?.grossVolume} m3
              </p>{" "}
            </div>
            <div>
              <p className="text-xs text-neutral-400 font-medium">
                Gross Weight
              </p>
              <p className="text-xxs font-medium">
                {quotation?.cargoDetails?.grossWeight} Kg
              </p>
            </div>
            <div>
              <p className="text-xs text-neutral-400 font-medium">Vessels</p>

              {quotation?.cargoDetails?.vessels &&
              quotation.cargoDetails.vessels.length > 0 ? (
                quotation.cargoDetails.vessels.map((vessel) => (
                  <p key={vessel.id} className="text-xxs font-medium">
                    {vessel.name}
                  </p>
                ))
              ) : (
                <p className="text-xxs font-medium text-neutral-400">
                  No vessels available
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {quotation?.cargoDetails.cargoes?.length > 0
        ? quotation?.cargoDetails.cargoes.map((cargo, index) => (
            <div className="bg-white rounded-md px-4 py-2 mt-2" key={index}>
              <h1 className="mb-1">{cargo?.poNo}</h1>
              <table className="w-full table-auto border-collapse">
                <tr className="bg-gray-100 text-xs">
                  <td className="border px-4 py-2 text-left">
                    Pieces/ Packages
                  </td>
                  <td className="border px-4 py-2 text-left">
                    Gross Volume (m³)
                  </td>
                  <td className="border px-4 py-2 text-left">Ch.Weight (Kg)</td>
                  <td className="border px-4 py-2 text-left">
                    Gross Weight (Kg)
                  </td>
                  <td className="border px-4 py-2 text-left">Length (cm)</td>
                  <td className="border px-4 py-2 text-left">Width (cm)</td>
                  <td className="border px-4 py-2 text-left">Height (cm)</td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">{cargo.quantity}</td>
                  <td className="border px-4 py-2">{cargo.grossVolume}</td>
                  <td className="border px-4 py-2">{cargo.chargeWeight}</td>
                  <td className="border px-4 py-2">{cargo.grossWeight}</td>
                  <td className="border px-4 py-2">{cargo.length}</td>
                  <td className="border px-4 py-2">{cargo.width}</td>
                  <td className="border px-4 py-2">{cargo.height}</td>
                </tr>
              </table>
            </div>
          ))
        : ""}

      <div className=" mt-2 pb-5 break-inside-avoid">
        {quotation.shipment.map((x) =>
          x.options.map((y, index) => (
            <div
              key={1}
              className="bg-white rounded-lg px-5 shadow-md flex flex-col items-start  py-2 pr-3 gap-2 mt-2 "
            >
              <div className="flex gap-4 bg-[#002060]  justify-start items-start w-[400px] rounded-lg text-white py-2 ">
                <h3 className="text-sm font-semibold  text-center leading-tight w-full">
                  {quotation.modes.find((y) => y.id === x.mode)?.mode} Transport{" "}
                  {quotation.modes.find((y) => y.id === x.mode)?.mode.length > 2
                    ? index + 1
                    : ""}
                </h3>
              </div>

              <div className=" flex justify-between  items-center gap-6 py-3 w-full">
                <div>
                  <p className="text-gray-500 text-xs">Origin</p>
                  <p className="text-sm font-medium">
                    {x?.options[0]?.originName}
                  </p>
                </div>

                <div>
                  <p className="text-gray-500 text-xs">Destination</p>
                  <p className="text-sm font-medium">
                    {x?.options[0]?.destinationName}
                  </p>
                </div>

                <div>
                  <p className="text-gray-500 text-xs">
                    {y.services.length} Locations
                  </p>
                  <p className="text-gray-500 text-xs">
                    {y.services.reduce((servSum, service) => {
                      return servSum + service.details.length;
                    }, 0)}{" "}
                    Services
                  </p>
                </div>

                <p className="text-gray-800 font-semibold w-36">
                  Total: {quotation?.currencyCode}{" "}
                  {parseFloat(
                    y.services.reduce((servSum, service) => {
                      return (
                        servSum +
                        service.details.reduce((detSum, detail) => {
                          return detSum + detail.price * detail.quantity;
                        }, 0)
                      );
                    }, 0)
                  ).toFixed(2)}{" "}
                </p>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="bg-white p-5 my-3 mt-3 rounded-lg shadow-md   ">
        {quotation?.shipment?.map((ship, index) => (
          <div key={index} className="">
            <div className="text-xl font-semibold mb-4 text-white bg-[#002060] flex items-center px-2 py-1 gap-2">
              {shipmentModes.find((x) => x.id === ship.mode)?.mode}
            </div>
            <h3 className="font-bold text-lg text-gray-800 mb-1">
              Service Details
            </h3>
            {ship.options?.map((option, optionIndex) => (
              <div key={optionIndex} className="mb-4">
                <p className="my-1">
                  {shipmentModes.find((x) => x.id === ship.mode)?.mode} -{" "}
                  {option.optionTitle
                    ? option.optionTitle
                    : `option ${optionIndex + 1}`}
                </p>

                {option?.services.map((service, serviceIndex) => (
                  <div key={serviceIndex} className="mt-3">
                    {option.services.length > 1 && (
                      <div className="bg-gray-100 p-4 rounded-md grid gap-3 grid-cols-5 mb-4">
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Origin</div>
                          <div className="text-xs">{`${
                            service?.originName || "N/A"
                          }`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">
                            Destination
                          </div>
                          <div className="text-xs">{`${
                            service?.destinationName || "N/A"
                          }`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">
                            Gross Weight
                          </div>
                          <div className="text-xs">{`${
                            option.cargoDetailsOptions?.grossWeight || 0
                          }`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">
                            Chg. Weight
                          </div>
                          <div className="text-xs">{`${
                            option.cargoDetailsOptions?.chargeWeight || 0
                          }`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Quantity</div>
                          <div className="text-xs">{`${
                            option.cargoDetailsOptions?.quantity || 0
                          }`}</div>
                        </div>
                      </div>
                    )}

                    <div className="bg-gray-100 p-4 rounded-md">
                      <div className="grid grid-cols-1">
                        <p className="text-gray-800 px-2 text-sm flex gap-1">
                          <span>Title:</span>
                          <span className="font-medium ">{service.title}</span>
                        </p>
                      </div>
                      <div className="rounded-lg mt-3">
                        <table className="w-full bg-white">
                          <thead className="bg-gray-50 border-b-2 text-xs border-gray-200">
                            <tr>
                              <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                Service
                              </th>
                              <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                Quantity
                              </th>
                              <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                Price
                              </th>
                              <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                Description
                              </th>
                              <th className="px-4 py-2 text-right font-semibold text-gray-700 uppercase tracking-wider">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y text-xxs divide-gray-200">
                            {service.details?.map((x, detailIndex) => (
                              <tr
                                key={detailIndex}
                                className="transition-colors duration-200"
                              >
                                <td className="px-5 py-2 whitespace-nowrap text-gray-900">
                                  {x.service}
                                </td>
                                <td className="px-5 py-2 whitespace-nowrap text-gray-500">
                                  {x.quantity}
                                </td>
                                <td className="px-5 py-2 whitespace-nowrap text-gray-500">
                                  {parseFloat(x.price.toLocaleString()).toFixed(
                                    2
                                  )}{" "}
                                  {quotation.currencyCode}
                                </td>
                                <td className="px-5 py-2 text-gray-500">
                                  {x.description}
                                </td>
                                <td className="px-5 py-2 whitespace-nowrap text-right font-medium text-gray-900">
                                  {parseFloat(
                                    (x.price * x.quantity).toLocaleString()
                                  ).toFixed(2)}{" "}
                                  {quotation.currencyCode}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-between px-2 mt-5">
                        <p className=" text-gray-800 mb-2 w-2/3">
                          <span className="font-semibold"> REMARKS:</span>{" "}
                          {service.comment}
                        </p>
                        <div className="text-right text-lg font-medium">
                          Total: &nbsp;{quotation?.currencyCode}{" "}
                          <span className="text-lg font-medium">
                            {parseFloat(
                              service.details?.reduce(
                                (a, b) => a + b.price * b.quantity,
                                0
                              ) || 0
                            ).toFixed(2)}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="bg-white p-5 my-3 rounded-lg shadow-md print:break-before-page">
        <h3 className="text-xl font-semibold mb-4 text-white bg-[#002060] px-2 py-1">
          Terms and Conditions
        </h3>

        <div className="space-y-3 text-xs text-gray-700">
          {quotation.modes
            .filter((x) => terms[x.mode])
            .map((x, index) => (
              <div key={index} className="mb-6">
                <h3 className="font-bold text-sm mb-2">{x.mode}</h3>
                <ul className="list-decimal pl-6 space-y-2">
                  {terms[x.mode].map((term, termIndex) => (
                    <li key={termIndex} className="text-gray-600">
                      {term}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default QuotationDocumentContent;
