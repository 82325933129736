import React from "react";
import "../../index.scss";

const PackageDetails = ({ job, setJob, formDisabled }) => {
  const handleChange = (e) => {
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex-col bg-gray-200 h-full px-4 py-3 w-full">
      <div className="flex justify-between">
        <div>
          <div className="text-sm font-semibold">PACKAGE DETAILS</div>
          <div className="text-xxs font-normal text-zinc-700">
            No.of Boxes Shipped in Jobs
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="w-full justify-center px-2 py-1 bg-sky-600 border-transparent shadow-sm text-sm font-semibold rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          >
            Print
          </button>
        </div>
      </div>
      <div className="flex-col bg-white h-auto w-full mt-3 mb-4 border-neutral-400 border rounded-lg">
        <div className="flex w-full h-14">
          <div className="flex flex-col justify-center items-center h-full w-1/2 border-b border-r border-neutral-400">
            <div className="text-sm font-semibold">
              <input
                name="packageDetailsQuantity"
                type="text"
                className="border-0 h-6 w-9 text-sm font-semibold p-0"
                value={job.packageDetailsQuantity}
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>
            <div className="text-xxs font-normal text-neutral-500">
              Quantity
            </div>
          </div>
          <div className="flex flex-col justify-center items-center h-full w-1/2 px-2 border-b border-neutral-400">
            <div class="inline-flex rounded group h-6 w-full border-black border">
              <button
                type="button"
                className={`text-xs font-medium text-center w-1/2 rounded-tl rounded-bl ${
                  job.packageDetailsUom === 100
                    ? "bg-black text-white"
                    : "text-black bg-white"
                }`}
                onClick={() => setJob({ ...job, packageDetailsUom: 100 })}
                disabled={formDisabled}
              >
                Pallets
              </button>
              <button
                type="button"
                className={`text-xs font-medium w-1/2 text-center rounded-tr rounded-br ${
                  job.packageDetailsUom === 200
                    ? "bg-black text-white"
                    : "text-black bg-white"
                }`}
                onClick={() => setJob({ ...job, packageDetailsUom: 200 })}
                disabled={formDisabled}
              >
                Boxes
              </button>
            </div>
            {/* <div className="text-xxs font-normal text-neutral-500">UOM</div> */}
          </div>
        </div>
        <div className="flex items-center h-14 w-full">
          <div className="flex-col flex justify-center items-center w-1/2 h-full border-r border-neutral-400">
            <div className="text-sm font-semibold">
              <input
                name="packageDetailsGrossWeight"
                type="text"
                className="border-0 h-6 w-8 text-sm font-semibold p-0"
                value={job.packageDetailsGrossWeight}
                onChange={handleChange}
                disabled={formDisabled}
              />
              KG
            </div>
            <div className="text-xxs font-normal text-neutral-500">
              Gross Weight
            </div>
          </div>
          <div className="flex-col flex h-full justify-center items-center w-1/2">
            <div className="text-sm font-semibold">
              <input
                name="packageDetailsChargableWeight"
                type="text"
                className="border-0 h-6 w-8 text-sm font-semibold p-0"
                value={job.packageDetailsChargableWeight}
                onChange={handleChange}
                disabled={formDisabled}
              />
              KG
            </div>
            <div className="text-xxs font-normal text-neutral-500">
              Charge-able Weight
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
