import React, { useContext, useEffect, useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from "@telerik/kendo-intl";
import AppContext from "../../context/app-context";

const Events = ({ job, setJob, formDisabled }) => {
  const authCtx = useContext(AppContext);
  const defaultEvent = {
    eventType: null,
    eventDescription: "",
    eventDate: null,
  };
  const [eventType, setEventType] = useState();
  const [filteredEventType, setFilteredEventType] = useState();
  const [value, setValue] = useState(defaultEvent);
  const [loading, setLoading] = useState(false);

  const loadLookups = async () => {
    if (job.jobType) {
      let res = await axios.get(
        `${BASE_URL}/Lookup/EventType?JobType=${job.jobType}`
      );
      const eventTypeIds = job.events.map(
        (event) => event.logisticsEventTypeId
      );
      console.log(res,"asdasd");
      
      setEventType(res.data);
      setFilteredEventType(
        res.data.filter((item) => !eventTypeIds.includes(item.id))
      );
    }
  };



  const onEventTypeChange = (e) => {
    setValue({
      ...value,
      logisticsEventTypeId: e.value.id,
      eventName: e.value.name,
      eventType: e.value,
    });
  };

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  
  useEffect(() => {
    loadLookups();
  }, [job]);
  
  const onEventAddClick = async () => {
    setLoading(true);
    const res = await axios.post(
      `${BASE_URL}/Job/addEvent?jobId=${job.id}`,
      value
    );
    if (res.status === 200) {
      value.id = res.data; // new event id coming back
      setJob({
        ...job,
        events: [
          {
            ...value,
            createdUserName: authCtx.profile.displayname.split(" ")[0],
          },
          ...job.events,
        ],
      });
      setFilteredEventType(
        eventType.filter((item) => item.id !== value.logisticsEventTypeId)
      );
      setValue(defaultEvent);
      toast.success("Event created successfully!");
      setLoading(false);
    } else {
      toast.error("An error occured while creating Event!");
      setLoading(false);
    }
    setValue(defaultEvent);
  };

  const onDeleteClick = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEvent();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };  

  const deleteEvent = async () => {
    const event = job.events[0];
    const res = await axios.post(
      `${BASE_URL}/Job/deleteEvent?jobId=${job.id}&eventId=${event.id}`,
      null
    );
    if (res.status === 200) {
      const newEvents = job.events.filter((e) => e.id !== event.id);
      if (
        event.logisticsEventTypeId === 8 ||
        event.logisticsEventTypeId === 12
      ) {
        setJob({ ...job, jobStatus: "Active", events: [...newEvents] });
      } else {
        setJob({ ...job, events: [...newEvents] });
      }
      const deletedEvent = eventType.find(
        (e) => e.id === event.logisticsEventTypeId
      );
      setFilteredEventType([...filteredEventType, deletedEvent]);
      toast.success("Event deleted successfully!");
    } else {
      toast.error("An error occured while deleting Event!");
    }
  };

  useEffect(() => {
    loadLookups();
  }, []);

 

  return (
    <div className="flex-col px-3 py-4 overflow-hidden h-full">
      <h2 className=" text-sm font-semibold">EVENTS</h2>
      <div className="flex flex-col ml-2 gap-2 mt-3 mr-6">
        <div className="" id="event">
          <ComboBox
            data={filteredEventType}
            name="eventType"
            textField="name"
            dataItemKey="id"
            suggest={true}
            clearButton={true}
            className="w-full h-8 text-neutral-400 bg-inherit text-xxs"
            placeholder="Choose Action"
            onChange={onEventTypeChange}
            value={value?.eventType}
            disabled={formDisabled}
          />
        </div>
        <div className="flex gap-1">
          <div className="w-1/3">
            <input
              name="eventDescription"
              type="text"
              placeholder="Description"
              className="h-8 rounded-md border-zinc-300 w-full text-sm font-normal text-neutral-400 text-xxs"
              onChange={handleChange}
              value={value?.eventDescription}
              disabled={formDisabled}
            />
          </div>
          <div
            className="w-2/3 border-t border-x rounded-md border-gray-300"
            id="eventDateCompo"
          >
            <DateTimePicker
              name="eventDate"
              className="w-full h-8 rounded-md border-[#DBDBDB] text-xs text-neutral-400 text-xxs placeholder:text-black"
              placeholder="Event Date"
              format={"dd-MM-yyyy hh:mm:ss a"}
              value={value?.eventDate}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={onEventAddClick}
          className="h-8 w-28 ml-2 bg-[#0091CF] rounded border-[#0091CF] text-xs font-semibold text-white self-end disabled:opacity-30"
          disabled={
            value.eventDate === null ||
            value.eventType === null ||
            loading ||
            formDisabled
          }
        >
          {loading && (
            <svg
              aria-hidden="true"
              role="status"
              class="mr-2 inline h-5 w-5 animate-spin text-blue-300"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#FFF"
              ></path>
            </svg>
          )}
          ADD
        </button>
      </div>
      <div className="mt-6 overflow-y-scroll overflow-x-hidden h-56">
        <ol class="flex ml-6 mt-4 relative border-l border-[#D0D0D0] overflow-x-visible">
          <div className="flex flex-col justify-start">
            {job.events &&
              job.events.map((id, index) => {
                return (
                  <li
                    className={`flex ml-4 mt-6 h-5 ${
                      index === 0 ? "-mt-2" : "mt-6"
                    }`}
                    key={index}
                  >
                    <div className="w-full">
                      <img
                        src="greentick.svg"
                        className="absolute mt-1.5 -left-3 mr-3"
                      />
                      <p className="font-semibold text-xxs leading-3">
                        {id.eventName}
                      </p>
                      <p className=" font-medium text-[9px] text-slate-900 leading-3 truncate">
                        {id.createdUserName}, Time :&nbsp;
                        {formatDate(new Date(id.eventDate), "yyyy-MM-dd HH:mm")}
                      </p>
                      <p className="font-medium text-[9px] text-slate-900 leading-3">
                        {id.eventDescription}
                      </p>
                    </div>
                  </li>
                );
              })}
          </div>
          {job.events && job.events.length > 0 && (
            <div className="absolute right-3 -top-2">
              <button
                class=""
                type="button"
                onClick={onDeleteClick}
                disabled={formDisabled}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="darkblue"
                  class="w-4 h-4 mt-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default Events;
