import React, { useContext, useState } from "react";
import { formatDate } from "@telerik/kendo-intl";
import AppContext from "../../../context/app-context";

const Mbl = ({ job, poTotal, handleChange, handleAreaChange }) => {
  const authCtx = useContext(AppContext);
  const currentDate = new Date();
  const [isEditable, setIsEditable] = useState(true);
  return (
    <div>
      <div class="flex w-full justify-center text-[10px]" id="print_div">
        <div class="border-2 border-black w-[700px] min-h-[950px] portrait">
          <table class="text-[10px] w-full">
            <tbody>
              <tr>
                <td
                  class="border-black border-r border-b w-8 relative"
                  rowspan="9"
                >
                  <div class="absolute -rotate-90 -left-12 truncate">
                    Name & Address of Shipper
                  </div>
                </td>
                <td
                  class="border-black border-b bg-slate-300 text-center h-6 pl-2"
                  colspan="5"
                >
                  <h1 class="font-bold text-xs">DRAFT B/L</h1>
                </td>
              </tr>
              <tr class="h-6">
                <td class="border-black border shrink-0 bg-slate-200 font-bold pl-2">
                  SHIPPER
                </td>
                <td rowspan="2">
                  <div class="flex justify-end w-full h-full border-b border-black">
                    <img src="logo.png" alt="voyagelogo" className="h-16" />
                  </div>
                  <div class="w-full">
                    <table class="text-[10px] w-full">
                      <tbody>
                        <tr>
                          <td class=" border-r border-black bg-slate-200">
                            Booking Ref:
                          </td>
                          <td class=" font-bold editable">
                            <input
                              class="font-semibold pl-1 editable"
                              name="bookingRef"
                              onChange={handleChange}
                              value={job?.bookingRef}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r bg-slate-200">
                            VML's Job No.:
                          </td>
                          <td class="border-black border-t font-bold editable">
                            <input
                              class="font-semibold pl-1 editable"
                              name="jobNo"
                              onChange={handleChange}
                              value={job?.jobNo}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r bg-slate-200">
                            Issued by:
                          </td>
                          <td
                            class="border-black border-t pl-1 font-semibold editable"
                            contentEditable={isEditable}
                          >
                            {authCtx.profile.displayname}
                          </td>
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r bg-slate-200">
                            Email.
                          </td>
                          <td
                            class="border-black border-t pl-1 font-semibold editable"
                            contentEditable={isEditable}
                          >
                            {authCtx.profile.email}
                          </td>
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r bg-slate-200">
                            Date:
                          </td>
                          <td
                            class="border-black border-t pl-1 font-semibold editable"
                            contentEditable={isEditable}
                          >
                            {formatDate(new Date(), "dd-MM-yyyy")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="border-black border-t shrink-0 border-x p-2 "
                  rowspan="6"
                >
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xxs editable"
                    name="shipperName"
                    onChange={handleChange}
                    value={job?.shipperName}
                  />
                  <br />
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xxs editable"
                    name="shipperAddress"
                    rows={7}
                    onChange={(e) => handleAreaChange(e, 7)}
                    value={job?.shipperAddress}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="text-[10px] h-40 w-full">
            <tbody>
              <tr>
                <td class="border-black  border-r w-8 relative" rowspan="9">
                  <div class="absolute -rotate-90 -left-16 top-20 pl-3 truncate">
                    Name & Address of Consignee
                  </div>
                </td>
              </tr>
              <tr class="h-6 font-bold italic">
                <td class="border-black border-b border-t bg-slate-200 pl-2 w-[297px]">
                  CONSIGNEE
                </td>
                <td class="border-black border-b border-t border-l bg-slate-200 pl-2">
                  NOTIFY PARTY (if applicable)
                </td>
              </tr>
              <tr class="font-bold">
                <td class="border-black border-r p-2" rowspan="6">
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xxs editable"
                    name="consigneeName"
                    onChange={handleChange}
                    value={job?.consigneeName}
                  />
                  <br />
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xxs editable"
                    name="consigneeAddress"
                    rows={7}
                    onChange={(e) => handleAreaChange(e, 7)}
                    value={job?.consigneeAddress}
                  />
                </td>
                <td className="p-2">
                  <input
                    class="font-semibold editable"
                    name="notificationPartyName"
                    onChange={handleChange}
                    value={job?.notificationPartyName}
                  />
                  <br />
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 text-xxs leading-3 editable"
                    name="notifyPartyAddress"
                    rows={7}
                    onChange={(e) => handleAreaChange(e, 7)}
                    value={job?.notifyPartyAddress}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="text-[10px] w-full">
            <tbody>
              <tr>
                <td class="border-black border-t w-8 relative" rowspan="9">
                  <div class="absolute -rotate-90 -left-6 bottom-10 truncate">
                    Other Instructions
                  </div>
                </td>
              </tr>
              <tr class="h-6">
                <td class="border-black border-t border-l">
                  <div class="mb-10">
                    <table class="text-[10px] w-full">
                      <tbody class="font-bold">
                        <tr>
                          <td class="border-black border-r bg-slate-200 w-40 editable">
                            Freight Terms:
                          </td>
                          <input
                            class="font-semibold w-full bg-inherit editable"
                            name="freightTerms"
                            onChange={handleChange}
                            value={job?.freightTerms}
                          />
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r bg-slate-200 editable">
                            B/L TYPE:
                          </td>
                          <td class="editable">
                            <input
                              class="font-semibold w-full bg-inherit editable"
                              name="blType"
                              onChange={handleChange}
                              value={job?.blType}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="border-black border-t border-r border-b bg-slate-200">
                            Cargo Type:
                          </td>
                          <td class="editable">
                            <input
                              class="font-semibold w-full bg-inherit editable"
                              name="cargoType"
                              onChange={handleChange}
                              value={job?.cargoType}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="text-[10px]  w-full font-bold text-center">
            <tbody>
              <tr class=" bg-slate-200 h-3">
                <td class="border-black border-t border-r pl-2 w-1/4">
                  Port of Loading
                </td>
                <td class="border-black border-t border-r pl-2 w-1/4">
                  Port of Discharge
                </td>
                <td class="border-black border-t border-r pl-2 w-1/4">
                  Final Destination
                </td>
                <td class="border-black border-t pl-2 w-1/4">Carrier's Ref</td>
              </tr>
              <tr class=" border-black text-xs h-6">
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="origin"
                    onChange={handleChange}
                    value={job?.origin}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="destination"
                    onChange={handleChange}
                    value={job?.destination}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="destination"
                    onChange={handleChange}
                    value={job?.destination}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="carriersRef"
                    onChange={handleChange}
                    value={job?.carriersRef}
                  />
                </td>
              </tr>
              <tr class=" bg-slate-200 h-3">
                <td class="border-black border-t border-r pl-2">Vessel</td>
                <td class="border-black border-t border-r pl-2">Voyage</td>
                <td class="border-black border-t border-r pl-2">ETA SIN</td>
                <td class="border-black border-t pl-2">ETA DEST</td>
              </tr>
              <tr class=" text-xs h-6">
                <td class="border-black border-t border-r pl-2 editable">
                  <textarea
                    class="font-semibold resize-none overflow-clip h-7 border-0 focus:ring-0 p-0 leading-3 text-xs w-full text-center bg-inherit editable"
                    name="vesselName"
                    onChange={handleChange}
                    value={job?.seaVessel}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <textarea
                    class="font-semibold resize-none overflow-clip h-7 w-full border-0 focus:ring-0 p-0 leading-3 text-xs text-center bg-inherit editable"
                    name="voyage"
                    onChange={handleChange}
                    value={job?.voyage}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="eta"
                    onChange={handleChange}
                    value={job?.eta}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="etd"
                    onChange={handleChange}
                    value={job?.etd}
                  />
                </td>
              </tr>
              <tr class="  bg-slate-200 h-3">
                <td class="border-black border-t border-r border-b pl-2">
                  Marks & Numbers
                </td>
                <td class="border-black border-t border-r border-b pl-2">
                  Description of Goods
                </td>
                <td class="border-black border-t border-r border-b">
                  <div class="flex justify-center gap-2 pl-2">
                    <div class="border-r border-black text-start w-1/2">
                      Packages
                    </div>
                    <div class="w-1/2">Weight</div>
                  </div>
                </td>
                <td class="border-black border-t border-b pl-2">Volume</td>
              </tr>
              <tr className="h-40">
                <td className=" align-top text-left h-full editable">
                  <textarea
                    class="font-semibold resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xs bg-inherit editable"
                    name="marks"
                    rows={13}
                    onChange={(e) => handleAreaChange(e, 13)}
                    value={job?.marks}
                  />
                </td>
                <td className="align-top text-left editable">
                  SAID TO CONTAIN:
                  <textarea
                    class="font-semibold resize-nonen overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xs bg-inherit editable"
                    name="goodsDescription"
                    rows={11}
                    onChange={(e) => handleAreaChange(e, 11)}
                    value={job?.goodsDescription}
                  />
                </td>
                <td className=" align-top">
                  <div class="flex">
                    <div class="w-24 editable" contentEditable={isEditable}>
                      {poTotal?.totalColli}
                      &nbsp;&nbsp; PACKAGES
                    </div>
                    <div className="editable" contentEditable={isEditable}>
                      {poTotal?.totalWeight}
                      &nbsp;&nbsp; KGS
                    </div>
                  </div>
                </td>
                <td
                  className=" align-top editable"
                  contentEditable={isEditable}
                >
                  {poTotal?.totalVolume}
                  &nbsp;&nbsp;M3
                </td>
              </tr>
            </tbody>
          </table>
          <table class="text-[10px] w-full font-bold text-center">
            <tbody>
              <tr class="bg-slate-200 pl-2">
                <th class="border-black border-t border-r pl-2">Size</th>
                <th class="border-black border-t border-r pl-2">
                  Container Numbers
                </th>
                <th class="border-black border-t border-r pl-2">
                  Seal Numbers
                </th>
                <th class="border-black border-t border-r pl-2">Tare Weight</th>
                <th class="border-black border-t border-r pl-2">
                  Cargo Weight
                </th>
                <th class="border-black border-t pl-2">Packages/ Volume</th>
              </tr>
              <tr class="pl-2 h-4">
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="size1"
                    onChange={handleChange}
                    value={job?.size1}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="container1"
                    onChange={handleChange}
                    value={job?.container1}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="seal1"
                    onChange={handleChange}
                    value={job?.seal1}
                  />
                </td>
                <td
                  class="border-black border-t border-r pl-2 editable"
                  contentEditable={isEditable}
                >
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="tare1"
                    onChange={handleChange}
                    value={job?.tare1}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="cargo1"
                    onChange={handleChange}
                    value={job?.cargo1}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="volume1"
                    onChange={handleChange}
                    value={job?.volume1}
                  />
                </td>
              </tr>
              <tr class="pl-2 h-4">
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="size2"
                    onChange={handleChange}
                    value={job?.size2}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="container2"
                    onChange={handleChange}
                    value={job?.container2}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="seal2"
                    onChange={handleChange}
                    value={job?.seal2}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="tare2"
                    onChange={handleChange}
                    value={job?.tare2}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="cargo2"
                    onChange={handleChange}
                    value={job?.cargo2}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="volume2"
                    onChange={handleChange}
                    value={job?.volume2}
                  />
                </td>
              </tr>
              <tr class="pl-2 h-4">
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="size3"
                    onChange={handleChange}
                    value={job?.size3}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="container3"
                    onChange={handleChange}
                    value={job?.container3}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="seal3"
                    onChange={handleChange}
                    value={job?.seal3}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="tare3"
                    onChange={handleChange}
                    value={job?.tare3}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="cargo3"
                    onChange={handleChange}
                    value={job?.cargo3}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="volume3"
                    onChange={handleChange}
                    value={job?.volume3}
                  />
                </td>
              </tr>
              <tr class="pl-2 h-4">
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="size4"
                    onChange={handleChange}
                    value={job?.size4}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="container4"
                    onChange={handleChange}
                    value={job?.container4}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="seal4"
                    onChange={handleChange}
                    value={job?.seal4}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="tare4"
                    onChange={handleChange}
                    value={job?.tare4}
                  />
                </td>
                <td class="border-black border-t border-r pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="cargo4"
                    onChange={handleChange}
                    value={job?.cargo4}
                  />
                </td>
                <td class="border-black border-t pl-2 editable">
                  <input
                    class="font-semibold w-full text-center bg-inherit editable"
                    name="volume4"
                    onChange={handleChange}
                    value={job?.volume4}
                  />
                </td>
              </tr>
              <tr class="border-black border-y bg-neutral-300 pl-2 h-4">
                <td colspan="6"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Mbl;
