import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../constants";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [passwordMatch, setPasswordMatch] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState();
  const [resetPassword, setResetPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const OnHandleChange = (e) => {
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });
  };

  const OnSubmit = (e) => {
    if (resetPassword.newPassword != resetPassword.confirmPassword) {
      setPasswordMatch(false);
    } else {
      setDisable(true);
      setLoading(true);
      setPasswordMatch(true);
      const payload = {
        token: token,
        password: resetPassword.confirmPassword,
      };
      axios
        .post(`${BASE_URL}/Auth/ConfirmPassword`, payload)
        .then((res) => {
          toast.success("Password reset successfully");
          navigate(`/`);
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            setDisable(false);
            toast.error(error.response.data);
          } else {
            setDisable(false);
            toast.error("An error occured while Saving Password");
          }
        });
      setLoading(false);
    }
    e.preventDefault();
  };
  return (
    <div className="relative flex h-full w-full justify-center items-center">
      <div className="flex flex-col w-1/4">
        <div className="flex w-full justify-center">
          <img src="logo.png" className=" h-40" />
        </div>
        <form className="flex flex-col justify-center" onSubmit={OnSubmit}>
          <div className="text-sm font-semibold text-gray-900 mb-2">
            Enter New password
          </div>
          <div className="text-sm text-center mb-5">
            <input
              name="newPassword"
              type="password"
              className="mt-2 appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200"
              value={resetPassword.newPassword}
              onChange={OnHandleChange}
            />
          </div>
          <div className="flex flex-col">
            <label for="email" className="text-sm font-semibold text-gray-900">
              Confirm Password
            </label>
            <input
              name="confirmPassword"
              type="password"
              className={`${
                passwordMatch !== true
                  ? "border-red-500 focus:ring-2 focus:ring-red-500 ring-1 ring-red-500"
                  : "border-sky-500 focus:ring-2 focus:ring-sky-500 ring-1 ring-sky-200"
              } border mt-2 appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400`}
              value={resetPassword.confirmPassword}
              onChange={OnHandleChange}
            />
            {passwordMatch !== true && (
              <p className="text-red-600 text-xs">Password Didn't Match</p>
            )}
            <button
              type="submit"
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700 mt-6 w-full"
              disabled={disable}
            >
              {loading && (
                <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Change Password
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
